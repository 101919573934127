/* eslint-disable no-param-reassign */
import { type Session } from 'next-auth';
import { getSession, signOut } from 'next-auth/react';
import axios, {
  type AxiosError,
  type AxiosHeaders,
  AxiosInstance,
  type CreateAxiosDefaults,
  HttpStatusCode,
  InternalAxiosRequestConfig,
} from 'axios';

import { getCurrentToken, isTokenValid } from '../auth/utils';
import { isClient, isServer } from '../utils/environments';

type FailedRequest = {
  onSuccess: (token?: string) => void;
  onFailure: (error: AxiosError) => void;
};

const MAX_RETRY_COUNT = 3;

class ApiBuilder {
  private static instance: ApiBuilder;

  private session: Session | null = null;

  private isRefreshing = false;

  private failedRequestsQueue: FailedRequest[] = [];

  private isGettingSession = false;

  private sessionPromise: Promise<Session | null> | null = null;

  private constructor() {
    if (isClient()) {
      getSession().then(session => {
        this.session = session;
      });
    }
  }

  public static getInstance(): ApiBuilder {
    if (!ApiBuilder.instance) {
      ApiBuilder.instance = new ApiBuilder();
    }

    return ApiBuilder.instance;
  }

  public mount(config: CreateAxiosDefaults = {}) {
    const api = axios.create({
      ...config,
      withCredentials: true,
      paramsSerializer: { indexes: null },
    });

    api.interceptors.request.use(
      // eslint-disable-next-line @typescript-eslint/return-await
      async req => this.handleSessionTokenInterceptor(req)
    );

    api.interceptors.response.use(
      response => response,
      err => this.handleRefreshToken(api, err)
    );

    return api;
  }

  private async ensureSession(): Promise<Session | null> {
    if (this.isGettingSession) {
      return this.sessionPromise;
    }

    this.isGettingSession = true;

    this.sessionPromise = getSession().finally(() => {
      this.isGettingSession = false;
      this.sessionPromise = null;
    });

    return this.sessionPromise;
  }

  private processFailedRequestsQueue(error?: AxiosError, token?: string) {
    this.failedRequestsQueue.forEach(request => {
      if (token) {
        request.onSuccess(token);
      } else if (error) {
        request.onFailure(error);
      }
    });
    this.failedRequestsQueue = [];
  }

  private handleRefreshToken(api: AxiosInstance, error: AxiosError) {
    const originalConfig = { ...error.config, retryCount: 0 };

    if (!originalConfig?.retryCount) {
      originalConfig.retryCount = 0;
    }

    if (
      error.response?.status === HttpStatusCode.Unauthorized &&
      isClient() &&
      originalConfig.retryCount < MAX_RETRY_COUNT
    ) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;

        originalConfig.retryCount += 1;

        this.ensureSession()
          .then(session => {
            if (session?.error) {
              signOut({ redirect: false });
            }

            this.processFailedRequestsQueue(
              undefined,
              String(session?.user?.token)
            );
          })
          .catch(err => {
            this.processFailedRequestsQueue(err);
          })
          .finally(() => {
            this.isRefreshing = false;
          });
      }

      return new Promise((resolve, reject) => {
        this.failedRequestsQueue.push({
          onSuccess(token?: string) {
            if (!originalConfig) {
              reject(new Error('Original config not found'));
              return;
            }

            if (originalConfig.headers && token) {
              originalConfig.headers.setAuthorization(`Bearer ${token}`);
            }

            resolve(api(originalConfig));
          },
          onFailure(err) {
            reject(err);
          },
        });
      });
    }

    if (originalConfig.retryCount >= MAX_RETRY_COUNT) {
      console.error('Max retries reached. Forcing sign-in.');
      signOut({ redirect: false });
    }

    return Promise.reject(error);
  }

  private async handleSessionTokenInterceptor(
    requestConfig: InternalAxiosRequestConfig
  ) {
    if (isClient()) {
      let accessToken = getCurrentToken(this.session);

      const isSessionInvalid = !isTokenValid(accessToken);

      if (isSessionInvalid) {
        const session = await this.ensureSession();

        if (session?.error) {
          await signOut({ redirect: false });

          return requestConfig;
        }

        accessToken = getCurrentToken(session);

        this.session = session ?? this.session;
      }

      if (accessToken) {
        requestConfig.headers = (requestConfig.headers ?? {}) as AxiosHeaders;

        const hasAlreadySetAuthorization =
          !!requestConfig.headers.getAuthorization();

        if (!hasAlreadySetAuthorization) {
          requestConfig.headers.setAuthorization(`Bearer ${accessToken}`);
        }
      }
    }

    return requestConfig;
  }
}

export const lcmApi = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_LCM_API_URL,
});

export const authApiPublic = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL,
});

export const authApiInternal = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL_INTERNAL,
});

export const authApi = (): AxiosInstance => {
  if (isServer()) {
    return authApiInternal;
  }
  return authApiPublic;
};

export const peoplePlatformApi = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_API_URL,
});
