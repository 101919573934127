import { useMutation } from '@tanstack/react-query';

type CreateProxyPayload = {
  proxyEmployeeGlobalId: string;
};

type CreateProxyAPIResponse = {
  success: boolean;
};

async function createProxy(
  payload: CreateProxyPayload
): Promise<CreateProxyAPIResponse> {
  const data = await fetch('api/proxy/create', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });

  return data.json();
}

export function useCreateProxy() {
  return useMutation({ mutationFn: createProxy });
}
