import { styled } from '@ghq-abi/design-system';

export const StyledOrderButton = styled('button', {
  appearance: 'none',
  border: 0,
  backgroundColor: 'transparent',
  color: 'inherit',
  fontWeight: 'inherit',
  cursor: 'pointer',
  transition: '$promptly-ease',
  whiteSpace: 'pre',

  display: 'flex',
  alignItems: 'center',
  gap: '$sm',
  justifyContent: 'center',

  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },

  'svg#order-icon-arrow': {
    transition: '$quickly-ease',
    transform: 'rotate(180deg)',
  },

  variants: {
    full: {
      true: {
        width: '$full',
      },
    },
    rotateIcon: {
      true: {
        'svg#order-icon-arrow': {
          transform: 'rotate(0deg)',
        },
      },
    },
    text: {
      true: {
        cursor: 'default',
      },
    },
  },
});
