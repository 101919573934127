import Link from 'next/link';
import { ButtonV2 } from '@ghq-abi/design-system';
import { LinkIcon } from '@ghq-abi/design-system-icons';

type ToolkitProps = {
  title: string;
  link: string;
};

export const Toolkit: React.FC<ToolkitProps> = ({ title, link }) => {
  return (
    <Link href={link} target="_blank">
      <ButtonV2 leftIcon={<LinkIcon />} variant="borderless" size="md">
        {title}
      </ButtonV2>
    </Link>
  );
};
