import { useMutation } from '@tanstack/react-query';

import { nextApi } from '~/shared/services/server-api';

type StopProxyAPIResponse = {
  success: boolean;
};

async function stopProxy(): Promise<StopProxyAPIResponse> {
  return nextApi.post('/proxy/stop');
}

export function useStopProxy() {
  return useMutation({ mutationFn: stopProxy });
}
