import type { Pagination } from 'abi-lcm-common-types';

import type { PaginationAndSortParams } from '../types/PaginationObject';

const DEFULT_TAKE = 15;
const DEFAULT_PAGE = 1;

type UsePaginationProps = {
  pagination?: Pagination;
  queryParams: PaginationAndSortParams;
  onQueryParamsChange: (queryParams: Partial<PaginationAndSortParams>) => void;
};
export function usePagination({
  queryParams,
  pagination,
  onQueryParamsChange,
}: UsePaginationProps) {
  const currentPage = queryParams?.page ?? DEFAULT_PAGE;

  const safeTotalPageCount = () => {
    if (!pagination?.total) return 0;

    const take = queryParams?.take ?? DEFULT_TAKE;

    return Math.ceil(pagination.total / take);
  };

  const handlePageChange = () => {
    onQueryParamsChange({
      page: pagination?.nextPage ?? DEFAULT_PAGE,
    });
  };
  return {
    totalPageCount: safeTotalPageCount(),
    currentPage: currentPage - 1,
    totalCount: pagination?.total || 0,
    handlePageChange,
  };
}
