import { ButtonV2, Flex, Text } from '@ghq-abi/design-system';
import { ChevronUpIcon } from '@ghq-abi/design-system-icons';
import { T } from '@tolgee/react';

import { useViewMoreTextHandler } from './useViewMoreTextHandler';

export type ViewMoreTextProps = {
  lines: number;
  text: string;
};

export const ViewMoreText: React.FC<ViewMoreTextProps> = ({
  lines = 1,
  text,
}) => {
  const { textRef, shouldShowButton, viewMoreDisclosure } =
    useViewMoreTextHandler({
      lines,
    });

  return (
    <Flex direction="column" gap="sm">
      <Text
        ref={textRef}
        css={{
          textOverflow: 'ellipsis',
          limitLines: viewMoreDisclosure.isOpen ? 0 : lines,
        }}
      >
        {text}
      </Text>
      {shouldShowButton && (
        <ButtonV2
          size="sm"
          css={{ width: 'fit-content' }}
          variant="borderless"
          rightIcon={
            <ChevronUpIcon
              style={{
                transition: 'transform 0.3s ease-in-out',
                transform: viewMoreDisclosure.isOpen
                  ? 'rotate(0deg)'
                  : 'rotate(180deg)',
              }}
            />
          }
          onClick={viewMoreDisclosure.toggle}
        >
          <T keyName="common.view_more" ns="default" />
        </ButtonV2>
      )}
    </Flex>
  );
};
