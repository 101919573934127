import { TbArrowDown } from 'react-icons/tb';
import { CSS } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { StyledOrderButton } from './styles';

type OrderButtonProps = {
  children: string;
  rightAddon?: React.ReactNode;
  as?: 'button' | 'span';
  direction?: 'asc' | 'desc';
  disabled?: boolean;
  full?: boolean;
  onClick?: () => void;
  css?: CSS;
};

export function OrderButton({
  children,
  as = 'button',
  direction = 'asc',
  disabled = false,
  full = false,
  rightAddon,
  css,
  onClick,
}: OrderButtonProps) {
  const { t } = useTranslate('default');

  const getButtonTitle = () => {
    return t('common.order_by', {
      field: children,
      direction: t(
        `common.${direction === 'asc' ? 'descending' : 'ascending'}`
      ).toLowerCase(),
    });
  };

  return (
    <StyledOrderButton
      as={as}
      type={as === 'button' ? 'button' : undefined}
      disabled={as === 'button' ? disabled : undefined}
      rotateIcon={direction === 'desc'}
      full={full}
      title={as === 'button' ? getButtonTitle() : undefined}
      onClick={as === 'button' ? onClick : undefined}
      css={css}
      text={as === 'span'}
    >
      {children}
      {!disabled && as === 'button' && (
        <TbArrowDown
          id="order-icon-arrow"
          size={16}
          style={{ flexShrink: 0 }}
        />
      )}
      {rightAddon || null}
    </StyledOrderButton>
  );
}
