import React from 'react';
import Link from 'next/link';
import { ButtonV2 } from '@ghq-abi/design-system';
import { PdfIcon } from '@ghq-abi/design-system-icons';

type ResultsProps = {
  title: string;
  link: string;
};
export const Results: React.FC<ResultsProps> = ({ title, link }) => {
  return (
    <Link href={link} target="_blank">
      <ButtonV2 rightIcon={<PdfIcon />} variant="secondary" size="sm">
        {title}
      </ButtonV2>
    </Link>
  );
};
