import React from 'react';
import {
  ButtonV2,
  Dialog,
  Flex,
  reusables,
  useResponsive,
} from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { CircleCheck } from '../icons/CircleCheck';

interface SuccessDialogProps {
  title: string;
  description: React.ReactNode;
  buttonText: string;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose: () => void;
}

export function SuccessDialog({
  description,
  onClose,
  title,
  isOpen,
  buttonText,
  isLoading,
}: SuccessDialogProps) {
  const { t } = useTranslate('default');
  const { isMobile } = useResponsive({ useOnlyAbiTokens: true });
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content
        variant={isMobile ? 'sheet' : 'default'}
        css={{
          overflow: 'auto',
          pt: '$14',
          pb: '$6',
          '@lg': {
            pt: '$6',
            px: '$4',
            maxWidth: 'calc($lg - $7)',
          },
        }}
        className={reusables.scrollbar()}
      >
        <Dialog.Title
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: '$gray780',
            fontSize: '$3',
            fontWeight: '$medium',
            gap: '$lg',
            '@lg': {
              fontSize: '$5',
              fontWeight: '$bold',
            },
          }}
        >
          <CircleCheck size={40} />

          {title}
        </Dialog.Title>
        <Dialog.Close asChild>
          <Dialog.CloseIcon
            aria-label={t('common.close_dialog')}
            disabled={isLoading}
          />
        </Dialog.Close>

        <Dialog.Description
          css={{
            textAlign: 'center',
            fontWeight: '$medium',
            mt: '$md',
            mb: '$lg',
            color: '$gray780',
            fontSize: '$1',
            '@tabletSm': {
              fontSize: '$3',
            },
          }}
        >
          {description}
        </Dialog.Description>

        <Flex justify="center">
          <Dialog.Close asChild>
            <ButtonV2
              size="md"
              loading={isLoading}
              disabled={isLoading}
              onClick={onClose}
              css={{ width: '$44' }}
            >
              {buttonText}
            </ButtonV2>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog>
  );
}
