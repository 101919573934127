/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

export function useMenuListScroll() {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  function isScrollableContainer(element: HTMLDivElement | null) {
    return element ? element.scrollHeight > element.clientHeight : false;
  }

  useEffect(() => {
    function updateIsScrollable() {
      if (elementRef.current) {
        setIsScrollable(isScrollableContainer(elementRef.current));
      }
    }

    updateIsScrollable();
    window.addEventListener('resize', updateIsScrollable);

    return () => {
      window.removeEventListener('resize', updateIsScrollable);
    };
  }, [elementRef.current]);

  return { elementRef, isScrollable };
}
