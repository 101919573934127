import { BsExclamationTriangle } from 'react-icons/bs';
import { styled } from '@ghq-abi/design-system';
import { T } from '@tolgee/react';

import { capitalize } from '~/shared/utils/string';

const StyledBannerContainer = styled('div', {
  display: 'flex',
  bg: 'red',
  color: '$white',
  width: 'fit-content',
  borderRadius: '$2',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  px: '$lg',
  py: '$sm',
  gap: '$sm',
});

const StyledBannerText = styled('p', {
  color: '$white',
  fontWeight: '$bold',
  fontSize: '$sm',
});

type EnvironmentBannerProps = {
  environment: string;
};
export function EnvironmentBanner({ environment }: EnvironmentBannerProps) {
  return (
    <StyledBannerContainer>
      <BsExclamationTriangle size={16} />
      <StyledBannerText>
        <T
          keyName="enviroment_banner.label"
          ns="default"
          params={{ t: capitalize(environment) }}
        />
      </StyledBannerText>
    </StyledBannerContainer>
  );
}
