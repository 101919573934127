/* eslint-disable class-methods-use-this */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export class ServerApiBuilder {
  private static instance: ServerApiBuilder;

  public static getInstance(): ServerApiBuilder {
    if (!ServerApiBuilder.instance) {
      ServerApiBuilder.instance = new ServerApiBuilder();
    }
    return ServerApiBuilder.instance;
  }

  /**
   * Create a server-side Axios instance
   * @param config Axios configuration
   * @param token Authentication token (optional)
   * @returns AxiosInstance
   */
  public mount(
    config: AxiosRequestConfig = {},
    token?: string | null
  ): AxiosInstance {
    const api = axios.create({
      ...config,
      withCredentials: true,
    });

    // Attach token if provided
    api.interceptors.request.use(request => {
      if (!token) return request;

      request.headers = request.headers ?? {};
      const hasAuthHeader = !!request.headers.Authorization;

      if (!hasAuthHeader) {
        request.headers.Authorization = `Bearer ${token}`;
      }

      return request;
    });

    return api;
  }
}

export const serverAuthApi = (token?: string | null) =>
  ServerApiBuilder.getInstance().mount(
    {
      baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL_INTERNAL,
    },
    token
  );

export const serverLcmApi = (token?: string | null) =>
  ServerApiBuilder.getInstance().mount(
    {
      baseURL: process.env.NEXT_PUBLIC_LCM_API_URL,
    },
    token
  );

export const nextApi = ServerApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_NEXT_API_URL,
});
