import { Dropdown, IconButtonV2, Text } from '@ghq-abi/design-system';
import { ChevronDownIcon, ExcelIcon } from '@ghq-abi/design-system-icons';

type ExportExcelDropdownItem = {
  label: string;
  title: string;
  action: () => void;
  disabled?: boolean;
};
export type ExportExcelDropdownProps = {
  items: ExportExcelDropdownItem[];
  disabled?: boolean;
};

export function ExportExcelDropdown({
  items,
  disabled,
}: Readonly<ExportExcelDropdownProps>) {
  return (
    <Dropdown>
      <Dropdown.Trigger asChild disabled={disabled}>
        <IconButtonV2
          variant="secondary"
          size="md"
          icon={<ExcelIcon />}
          overrideSvgColor={false}
          additionalIcon={<ChevronDownIcon width={16} />}
        />
      </Dropdown.Trigger>
      <Dropdown.Content
        css={{
          mr: '$10',
          zIndex: '$dropdown',
          '& > *:not(:last-child)': {
            borderBottom: '1px solid $gray400',
            mx: '$2',
          },
        }}
      >
        {items.map(item => (
          <Dropdown.Item
            key={item.label}
            onClick={item.action}
            title={item.title}
          >
            <Text>{item.label}</Text>
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
}
