import { GetServerSidePropsContext, NextApiRequest } from 'next';
import { Session } from 'next-auth';
import { DecodedJwtToken, SecurityRoleTypeEnum } from 'abi-lcm-common-types';
import { isPast } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { parseCookies } from 'nookies';

import { COOKIE_KEYS } from '~/shared/constants/cookies';

import { PeoplePlatformSecurityRole } from '../types/people-platform';

export function mountRoles(roles: PeoplePlatformSecurityRole[]) {
  const roleTypes = Object.values(SecurityRoleTypeEnum);

  const mountedRoles = {} as Record<`IS_${SecurityRoleTypeEnum}`, boolean>;

  roleTypes.forEach(roleType => {
    mountedRoles[`IS_${roleType}`] = roles.some(role => role.type === roleType);
  });

  return mountedRoles;
}

export function isTokenValid(token?: string | null) {
  try {
    if (!token) return false;

    const { exp } = jwtDecode<DecodedJwtToken>(token);
    const expirationDate = new Date(exp * 1000);

    if (isPast(expirationDate)) return false;

    return true;
  } catch {
    return false;
  }
}

export function getAuthTokens(data?: {
  context?: GetServerSidePropsContext;
  req?: NextApiRequest;
}) {
  let cookies = parseCookies();

  if (data?.context) cookies = parseCookies(data.context);

  if (data?.req) cookies = parseCookies({ req: data.req });

  const proxiedTokens = cookies[COOKIE_KEYS.PROXIED_USER_TOKENS];
  const userTokens = cookies[COOKIE_KEYS.USER_TOKENS];

  if (proxiedTokens) {
    const decodedProxiedTokens = JSON.parse(proxiedTokens);

    return decodedProxiedTokens ?? null;
  }

  if (userTokens) {
    const decodedUserTokens = JSON.parse(userTokens);

    return decodedUserTokens ?? null;
  }

  return null;
}

export const getCurrentToken = (session: Session | null) => {
  return session?.user?.proxiedAs?.token ?? session?.user.token ?? '';
};
