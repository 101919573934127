import { AssessmentType } from './AssessmentType';
import { Band } from './Band';
import { ContentRoot } from './ContentRoot';
import { FirstAndLastName } from './FirstAndLastName';
import { FullName } from './FullName';
import { GlobalId } from './GlobalId';
import { InfoTooltip } from './InfoTooltip';
import { Persona } from './Persona';
import { PositionAndZone } from './PositionAndZone';
import { RaterRelationship } from './RaterRelationship';
import { Results } from './Results';
import { TalentCard } from './TalentCard';
import { Toolkit } from './Toolkit';

export const Content = {
  Root: ContentRoot,
  FullName,
  FirstAndLastName,
  PositionAndZone,
  AssessmentType,
  RaterRelationship,
  Band,
  GlobalId,
  Persona,
  TalentCard,
  InfoTooltip,
  Results,
  Toolkit,
};
