import { useEffect, useRef, useState } from 'react';

import { useDisclosure } from '~/shared/hooks';

type ViewMoreTextHandlerProps = {
  lines: number;
};
export function useViewMoreTextHandler({ lines }: ViewMoreTextHandlerProps) {
  const viewMoreDisclosure = useDisclosure();
  const textRef = useRef<HTMLDivElement>(null);

  const [shouldShowButton, setShouldShowButton] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const element = textRef.current;
      const isOverflowing = element.scrollHeight > element.offsetHeight;
      setShouldShowButton(isOverflowing);
    }
  }, [lines, textRef]);

  return {
    textRef,
    shouldShowButton,
    viewMoreDisclosure,
  };
}
