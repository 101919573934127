import { useMemo } from 'react';

import { useTolgeeLocale } from './useTolgeeLocale';

/**
 * Hook to sort a list of objects alphabetically by locale.
 *
 * @param list Array of generic objects.
 * @param getKey Function to get the key to be sorted.
 * @returns Sorted list.
 */
export function useLocaleSort<T>(list: T[], getKey: (item: T) => string): T[] {
  const locale = useTolgeeLocale();

  return useMemo(() => {
    const collator = new Intl.Collator(locale, {
      sensitivity: 'base',
    });

    return [...list].sort((a, b) => {
      const keyA = getKey(a);
      const keyB = getKey(b);

      return collator.compare(keyA, keyB);
    });
  }, [list, getKey, locale]);
}
