import { useSessionInfo } from '~/app/contexts/SessionInfoContext';

import { LcmUserSession } from './types/next-auth';

export function useUser(): LcmUserSession['user'] {
  const { sessionInfo } = useSessionInfo();

  return {
    name: sessionInfo?.user?.proxiedAs?.name ?? sessionInfo?.user?.name,
    zone: sessionInfo?.user?.proxiedAs?.zone ?? sessionInfo?.user?.zone,
    globalId:
      sessionInfo?.user?.proxiedAs?.globalId ?? sessionInfo?.user?.globalId,
    employeeId:
      sessionInfo?.user?.proxiedAs?.employeeId ?? sessionInfo?.user?.employeeId,
    zones: sessionInfo?.user?.proxiedAs?.zones ?? sessionInfo?.user?.zones,
    roles: sessionInfo?.user?.proxiedAs?.roles ?? sessionInfo?.user?.roles,
    preferredLanguage: sessionInfo?.user?.preferredLanguage,
    idpPermission: !!sessionInfo?.user?.idpPermission,
    position:
      sessionInfo?.user?.proxiedAs?.position ?? sessionInfo?.user?.position,
    surveyType:
      sessionInfo?.user?.proxiedAs?.surveyType ?? sessionInfo?.user?.surveyType,
  };
}

export type User = ReturnType<typeof useUser>;
