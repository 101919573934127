export const COOKIE_KEYS_OLD = {
  USER_PREFERRED_LANGUAGE: '@lcm/user-preferred-language',
  USER_NONCE: '@lcm/user-nonce',
  USER_TOKENS: '@lcm/user-tokens',
  PROXIED_USER_TOKENS: '@lcm/proxied-user-tokens',
} as const;

export const COOKIE_KEYS = {
  USER_PREFERRED_LANGUAGE: '@lcm/v2/user-preferred-language',
  USER_NONCE: '@lcm/v2/user-nonce',
  USER_TOKENS: '@lcm/v2/user-tokens',
  PROXIED_USER_TOKENS: '@lcm/v2/proxied-user-tokens',
} as const;
