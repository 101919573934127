import Script from 'next/script';

import { LcmUserSession } from '~/shared/auth/types/next-auth';

type NpsMetricScriptProps = {
  session?: LcmUserSession;
};

function NpsMetricScript({ session }: NpsMetricScriptProps) {
  return (
    <>
      {/* Load external script with error handling */}
      <Script
        type="text/javascript"
        src={process.env.NEXT_PUBLIC_METRICS_URL}
        strategy="afterInteractive"
        onError={() => {
          console.error('Failed to load the metrics script.');
        }}
      />

      {/* Inline script for metrics plugin initialization */}
      <Script id="plugin" strategy="afterInteractive">
        {`
          (function initializeMetrics() {
            try {
              var metricsPlugin = window.metricsPlugin;
              if (!metricsPlugin || typeof metricsPlugin.plugin !== 'function') {
                console.warn('Metrics plugin is not available or not properly loaded.');
                return;
              }
              
              setTimeout(() => {
                metricsPlugin.plugin(
                  "${session?.user?.globalId || ''}",
                  "LCM",
                  "${session?.user?.preferredLanguage || 'en'}"
                );
              }, 2000);
            } catch (error) {
              console.error('Error initializing metrics plugin:', error);
            }
          })();
        `}
      </Script>
    </>
  );
}

export const NPS = {
  Script: NpsMetricScript,
};
