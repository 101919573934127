import { Flex } from '@ghq-abi/design-system';

import { useNav } from '~/app/contexts/NavContext';
import { useUser } from '~/shared/auth/useUser';
import { EnvironmentBanner } from '~/shared/components';
import { getEnv } from '~/shared/utils/environments';

import { HeaderActions } from '../HeaderActions';
import { LanguageDropdownHeader } from '../LanguageDropdown';
import { ModulesDropdown } from '../ModulesDropdown';
import { UserDropdown } from '../UserDropdown';

import { StyledHeader } from './styles';

export function Header() {
  const user = useUser();

  const { isAppMenuOpen } = useNav();

  const env = getEnv();

  return (
    <StyledHeader isMenuOpen={isAppMenuOpen}>
      <HeaderActions />
      {env !== 'production' && <EnvironmentBanner environment={env} />}
      <Flex gap="md" align="center">
        <LanguageDropdownHeader currentLanguage={user.preferredLanguage} />
        <ModulesDropdown />
        {user && <UserDropdown user={user} />}
      </Flex>
    </StyledHeader>
  );
}
